export const state = {
  clientCompareList: [],
  selectedClientsList: [],
  teams: [],
  teamResults: null,
  teamComparisonResults: null,
};

export const mutations = {
  ADD_CLIENT_COMPARE(state, payload) {
    state.clientCompareList = payload;
  },
  REMOVE_CLIENT_COMPARE(state, payload) {
    const clientIndex = state.clientCompareList.findIndex((client) => client.token === payload);
    state.clientCompareList.splice(clientIndex, 1);
  },

  SELECT_CLIENT(state, payload) {
    state.selectedClientsList.push(payload);
  },
  UNSELECT_CLIENT(state, payload) {
    const index = state.selectedClientsList.findIndex((element) => element.names === payload.names);

    if (index > -1) {
      state.selectedClientsList.splice(index, 1);
    } else {
      console.error('Unable to unselect client', payload);
    }
  },
  EMPTY_CLIENT_SELECTION_LIST(state) {
    state.selectedClientsList = [];
  },
  EMPTY_CLIENT_COMPARE(state) {
    state.clientCompareList = [];
  },
  CREATE_TEAM_SUCCESS() {},
  CREATE_TEAM_FAILURE() {},
  DELETE_TEAM_FAILURE() {},
  FETCH_TEAMS_SUCCESS(state, payload) {
    state.teams = payload;
  },
  FETCH_TEAMS_FAILURE() {},
  ADD_TEAM_MEMBER_SUCCESS() {},
  ADD_TEAM_MEMBER_FAILURE() {},
  DEL_TEAM_MEMBER_SUCCESS() {},
  DEL_TEAM_MEMBER_FAILURE() {},
  FETCH_TEAM_RESULTS_FAILURE() {},
  FETCH_TEAM_RESULTS_SUCCESS(state, payload) {
    state.teamResults = payload;
  },
  FETCH_TEAM_COMPARISON_SUCCESS(state, payload) {
    state.teamComparisonResults = payload;
  },
  FETCH_TEAM_COMPARISON_FAILURE() {},
  FETCH_AVAILABLE_WEIGHTS_FAILURE() {},
  FETCH_AVAILABLE_WEIGHTS_SUCCESS() {},
};
