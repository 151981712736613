import { initials } from '@/utils/i18n';
import { validatePermission } from '@/utils/permission';
import { PERMISSION_OPERATION, PERMISSION_REALM, PERMISSION_RESOURCE } from '@/constants/userPermissions';
import { ACCOUNT_TYPE } from '@/constants/user';
import { PRODUCT } from '@/constants/interaction';

export default {
  user: (state) =>
    state.user && {
      ...state.user,
      initials: initials(state.user.firstName, state.user.lastName),
    },
  isAdmin: (state) => {
    return (
      state.user &&
      validatePermission(
        state.user.permissions,
        PERMISSION_REALM.ALL,
        PERMISSION_RESOURCE.ALL,
        PERMISSION_OPERATION.ALL
      )
    );
  },
  isSelfService: (state) => state.user.accountType === ACCOUNT_TYPE.SELF_SERVICE,
  isBQA: (state) => (route) => {
    const pathInBQA = route && route.path && route.path.includes('bqa/report');
    const userInBQA =
      state.user &&
      state.user.currentProduct &&
      state.user.currentProduct.productType === PRODUCT.BQ_ADVISORY_WEALTH.productType;
    return pathInBQA || userInBQA;
  },
  isBQP: (state) => (route) => {
    const pathInBQP = route && route.path && route.path.includes('performance/report');
    const userInBQP =
      state.user &&
      state.user.currentProduct &&
      state.user.currentProduct.productType === PRODUCT.BQ_SELECTION.productType;
    return pathInBQP || userInBQP;
  },
  clientLoading: (state) => state.clientLoading > 0,
  createUserLimits: (state) => state.createUserLimits,
  currentProduct: (state) => state.currentProduct,
  currentProductConfiguration: (state) =>
    state.user &&
    state.user.products &&
    state.user.products.find((productConfig) => productConfig.productType === state.currentProduct.productType),
  currentProductTranslationKey: (state) => state.currentProduct && state.currentProduct.productTranslationKey,
  firstAvailableProduct: (state) => state.user && state.user.products && state.user.products[0],
  privacyPolicyData: (state) => state.privacyPolicyData,
  redirectUrl: (state) => state.redirectUrl,
  socialsAuthLoading: (state) => state.socialsAuthLoading,
};
