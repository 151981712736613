export const ADD_CLIENT_COMPARE = 'ADD_CLIENT_COMPARE';
export const EMPTY_CLIENT_COMPARE = 'EMPTY_CLIENT_COMPARE';
export const REMOVE_CLIENT_COMPARE = 'REMOVE_CLIENT_COMPARE';
export const FETCH_TEAMS_SUCCESS = 'FETCH_TEAMS_SUCCESS';
export const FETCH_TEAMS_FAILURE = 'FETCH_TEAMS_FAILURE';
export const CREATE_TEAM_SUCCESS = 'CREATE_TEAM_SUCCESS';
export const CREATE_TEAM_FAILURE = 'CREATE_TEAM_FAILURE';
export const DELETE_TEAM_SUCCESS = 'DELETE_TEAM_SUCCESS';
export const DELETE_TEAM_FAILURE = 'DELETE_TEAM_FAILURE';
export const ADD_TEAM_MEMBER_SUCCESS = 'ADD_TEAM_MEMBER_SUCCESS';
export const ADD_TEAM_MEMBER_FAILURE = 'ADD_TEAM_MEMBER_FAILURE';
export const DEL_TEAM_MEMBER_SUCCESS = 'DEL_TEAM_MEMBER_SUCCESS';
export const DEL_TEAM_MEMBER_FAILURE = 'DEL_TEAM_MEMBER_FAILURE';
export const FETCH_TEAM_RESULTS_SUCCESS = 'FETCH_TEAM_RESULTS_SUCCESS';
export const FETCH_TEAM_RESULTS_FAILURE = 'FETCH_TEAM_RESULTS_FAILURE';
export const FETCH_TEAM_COMPARISON_SUCCESS = 'FETCH_TEAM_COMPARISON_SUCCESS';
export const FETCH_TEAM_COMPARISON_FAILURE = 'FETCH_TEAM_COMPARISON_FAILURE';
export const FETCH_AVAILABLE_WEIGHTS_SUCCESS = 'FETCH_AVAILABLE_WEIGHTS_SUCCESS';
export const FETCH_AVAILABLE_WEIGHTS_FAILURE = 'FETCH_AVAILABLE_WEIGHTS_FAILURE';
export const FETCH_WEIGHT_PROFILES_SUCCESS = 'FETCH_AVAILABLE_WEIGHTS_SUCCESS';
export const FETCH_WEIGHT_PROFILES_FAILURE = 'FETCH_AVAILABLE_WEIGHTS_SUCCESS';
export const DELETE_WEIGHT_PROFILE_SUCCESS = 'DELETE_WEIGHT_PROFILE_SUCCESS';
export const DELETE_WEIGHT_PROFILE_FAILURE = 'DELETE_WEIGHT_PROFILE_FAILURE';
export const UPDATE_WEIGHT_PROFILE_SUCCESS = 'UPDATE_WEIGHT_PROFILE_SUCCESS';
export const UPDATE_WEIGHT_PROFILE_FAILURE = 'UPDATE_WEIGHT_PROFILE_FAILURE';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const UNSELECT_CLIENT = 'UNSELECT_CLIENT';
export const EMPTY_CLIENT_SELECTION_LIST = 'EMPTY_CLIENT_SELECTION_LIST';
